$("#video_report-chart").on("click", function(evt) {
  chart = Chartkick.charts['video_report-chart'].getChartObject()
  var firstPoint = chart.getActiveElements(evt)[0]
  if(firstPoint) {
    var label = chart.data.labels[firstPoint.index]
    value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]
    window.location.href = `/school/reports/video?branchName=${label}`
  }
})

$("#quiz_report-chart").on("click", function(evt) {
  chart = Chartkick.charts['quiz_report-chart'].getChartObject()
  var firstPoint = chart.getActiveElements(evt)[0]
  if(firstPoint) {
    var label = chart.data.labels[firstPoint.index]
    value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]
    window.location.href = `/school/reports/quiz?branchName=${label}`
  }
})

$("#practice_test-chart").on("click", function(evt) {
  chart = Chartkick.charts['practice_test-chart'].getChartObject()
  var firstPoint = chart.getActiveElements(evt)[0]
  if(firstPoint) {
    var label = chart.data.labels[firstPoint.index]
    value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]
    window.location.href = `/school/reports/practice_test?branchName=${label}`
  }
})